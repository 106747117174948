import { styled } from "@mui/material/styles";
import { ProductCardMobile, ProductCardDesktop } from "containers/ProductCard";
import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";
import useIsMobile from "hooks/useIsMobile";
import { ProductUrlGenerator } from "hooks/useProductUrlGenerator";
import { HTMLAttributes } from "react";

import { BookingProductCardV2 } from "./BookingProductCardV2";

type ProductItem = $TSFixMe;
type ProductListProps = {
    products: ProductItem[];
    urlGenerator: ProductUrlGenerator;
    onProductClick?: (productItem: ProductItem, position: number) => void;
    onFavouriteChange?: (productId: string, isFavourite: boolean) => void;
} & HTMLAttributes<HTMLDivElement>;

export const ProductList = ({
    urlGenerator,
    products,
    onFavouriteChange,
    onProductClick,
    ...props
}: ProductListProps) => {
    const withWhiteLabelDesignV2 = useEntityConfigurationValueByKey("SETTING.WHITE_LABEL_DESIGN_V2") ?? false;

    return (
        <ProductListWrapper {...props}>
            {products.map((product: $TSFixMe, index: number) => {
                const href = urlGenerator(product);
                return withWhiteLabelDesignV2 ? (
                    <BookingProductCardV2
                        {...product}
                        key={product.id}
                        orientation="horizontal"
                        href={href}
                        height={230}
                        onProductClick={(item) => {
                            onProductClick?.(item, index);
                        }}
                        imageMaxWidth={300}
                        onFavouriteChange={(isFavourite) => onFavouriteChange?.(product.id, isFavourite)}
                    />
                ) : (
                    <ProductCardHorizontal
                        key={product.id}
                        product={product}
                        href={href}
                        onFavouriteChange={(isFavourite) => onFavouriteChange?.(product.id, isFavourite)}
                    />
                );
            })}
        </ProductListWrapper>
    );
};

type ProductCardHorizontalProps = {
    product: ProductItem;
    href: string;
    onFavouriteChange: (isFavourite: boolean) => void;
    onProductClick?: (productItem: ProductItem) => void;
};

const ProductCardHorizontal = ({ product, href, onFavouriteChange, onProductClick }: ProductCardHorizontalProps) => {
    const isMobile = useIsMobile();

    const newTab = useEntityConfigurationValueByKey<boolean>("SETTING.PRODUCT_CARD_NEW_TAB");

    const ProductCard = isMobile ? ProductCardMobile : ProductCardDesktop;
    const sizes = "(max-width: 780px) 100vw, 300px";

    return (
        <ProductCard
            newTab={newTab}
            product={product}
            href={href}
            sizes={sizes}
            onProductClick={(item) => {
                onProductClick?.(item);
            }}
            onFavouriteChange={onFavouriteChange}
        />
    );
};

export const ProductListWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.airiness,
}));
