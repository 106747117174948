"use client";

import { RefetchQueriesFunction } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import ApiErrorAlert from "components/ApiErrorAlert";
import Text, { TextTitle2 } from "components/Text";
import { useViewItemList } from "containers/GoogleAnalytics/googleAnalyticsEvents";
import Paganate from "containers/Paganate";
import { ProductList } from "containers/ProductList";
import { ProductUrlGenerator } from "hooks/useProductUrlGenerator";
import { useNextTranslation } from "hooks/useTranslation";
import { useSearchParams } from "next/navigation";
import React, { ForwardedRef, forwardRef, useImperativeHandle } from "react";

import { ErrorBoundary, Spacer } from "@holibob-packages/ui-core/components";
import { ProductItem } from "@holibob-packages/ui-core/types";

import ProductFilteredListFilters from "./ProductFilteredListFilters";
import ProductFilteredListLayout from "./ProductFilteredListLayout";
import ProductFilteredListSearch from "./ProductFilteredListSearch";
import { ProductFilteredListFilterTrees } from "./Tree/ProductFilteredListFilterTrees";
import {
    ProductFilteredListStateContext,
    useProductFilteredListCreateContext,
    useProductFilteredListError,
    useProductFilteredListHeader,
    useProductFilteredListLoading,
    useProductFilteredListPaginationProps,
    useProductFilteredListProducts,
    useProductFilteredListSearchTerm,
    useProductFilteredListTitle,
    UseProductFilteredListCreateContextParams,
    useProductFilteredListContext,
    ProductFilteredListStateContextValue,
} from "./hooks";

type ProductFilteredListContentProps = Pick<ProductFilteredListProps, "urlGenerator" | "onFavouriteChange"> & {
    onProductClick?: (productItem: ProductItem, position: number) => void;
};

const ProductFilteredListContent = ({
    urlGenerator,
    onFavouriteChange,
    onProductClick,
}: ProductFilteredListContentProps) => {
    const products = useProductFilteredListProducts();
    const error = useProductFilteredListError();
    const paginationProps = useProductFilteredListPaginationProps();
    const searchTerm = useProductFilteredListSearchTerm();
    const loading = useProductFilteredListLoading();
    let header = useProductFilteredListHeader();
    let title = useProductFilteredListTitle();

    const searchParams = useSearchParams();
    const curationId = searchParams.get("curationId");
    const placeId = searchParams.get("placeId");
    const categoryId = searchParams.get("categoryId");

    let entityId = undefined;
    let entityType = undefined;
    if (curationId) {
        entityId = curationId;
        entityType = "Curation";
    } else if (placeId) {
        entityId = placeId;
        entityType = "Place";
    } else if (categoryId) {
        entityId = categoryId;
        entityType = "Category";
    }

    useViewItemList(entityId, entityType, products);

    const [t] = useNextTranslation("product");
    const noProductFoundMessage = t("message.noProductFound");

    if (error) return <ApiErrorAlert error={error} />;

    if (typeof title === "string") title = <TextTitle2>{title}</TextTitle2>;
    if (!header) header = title;

    let productList = null;
    if (loading) productList = <CircularProgress color="primary" />;
    else if (products.length)
        productList = (
            <ProductList
                products={products}
                urlGenerator={urlGenerator}
                onProductClick={onProductClick}
                onFavouriteChange={onFavouriteChange}
            />
        );
    else productList = <Text variant="body2">{noProductFoundMessage}</Text>;

    const content = (
        <div>
            <ProductFilteredListSearch />
            <Spacer sm />
            <ProductFilteredListFilters />
            <Spacer sm />
            {productList}
        </div>
    );

    const footer = loading ? null : <Paganate {...paginationProps} searchTerm={searchTerm} />;

    const sidebar = <ProductFilteredListFilterTrees />;

    return <ProductFilteredListLayout header={header} sidebar={sidebar} content={content} footer={footer} />;
};

const ProductFilteredListContentWrapper = (props: ProductFilteredListContentProps) => {
    // TODO HD-3792 - Add types to the ProductFilteredListStateContext
    const { filter, hide } = useProductFilteredListContext();
    const searchFilter = filter.search;
    const hasPriceFilter = !!(filter.priceStart ?? filter.priceEnd);

    const shouldHide = hide && !searchFilter && !hasPriceFilter;

    if (shouldHide) return null;

    return <ProductFilteredListContent {...props} />;
};

type ProductFilteredListProps = UseProductFilteredListCreateContextParams & {
    urlGenerator: ProductUrlGenerator;
    onFavouriteChange?: (productId: string, isFavourite: boolean) => void;
    titleComponent?: (props: { total?: number; interval?: string }) => React.JSX.Element | null;
    onProductClick?: (productItem: ProductItem, position: number) => void;
};

export type ProductFilteredListContainerRef = {
    refetch?: RefetchQueriesFunction;
};

export const ProductFilteredListContainer = forwardRef(function ProductFilteredListContainer(
    {
        urlGenerator,
        onFavouriteChange,
        onProductClick,
        titleComponent: TitleComponent,
        ...restProps
    }: ProductFilteredListProps,
    ref: ForwardedRef<ProductFilteredListContainerRef | undefined>
) {
    const value = useProductFilteredListCreateContext(restProps);

    const { productsResponse, total, interval } = value;
    const { error, hasRootErrors, refetch } = productsResponse;
    useImperativeHandle(
        ref,
        () => {
            return {
                refetch,
            };
        },
        [refetch]
    );

    if (error && hasRootErrors) return <ApiErrorAlert error={error} />;

    const providerFilteredListStateValue: ProductFilteredListStateContextValue = {
        ...value,
        ...(TitleComponent && { title: <TitleComponent total={total} interval={interval} /> }),
    };

    return (
        <ErrorBoundary>
            <ProductFilteredListStateContext.Provider value={providerFilteredListStateValue}>
                <ProductFilteredListContentWrapper
                    urlGenerator={urlGenerator}
                    onProductClick={onProductClick}
                    onFavouriteChange={onFavouriteChange}
                />
            </ProductFilteredListStateContext.Provider>
        </ErrorBoundary>
    );
});

export default ProductFilteredListContainer;
