import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

import { useProductFilteredListFilter, useProductFilteredListLoading } from "../hooks";
import { FilterTreeTitle } from "./ProductFilteredListFilterTreeTitle";

type FilterLeafProps = {
    id: string;
    isSelected?: boolean;
    onSelect?: (id: string) => void;
    label: string;
    count?: number;
    depth: number;
};

export const FilterLeaf = ({ id, isSelected: isSelectedProp, onSelect, label, count, depth }: FilterLeafProps) => {
    const [isSelected, toggleIsSelected] = useState(isSelectedProp);
    const loading = useProductFilteredListLoading();
    const filters = useProductFilteredListFilter();

    if (filters.providerIds?.includes(id) && !isSelected) {
        toggleIsSelected(true);
    } else if (!filters.providerIds?.includes(id) && isSelected) {
        toggleIsSelected(false);
    }

    useEffect(() => {
        toggleIsSelected(isSelectedProp);
    }, [isSelectedProp]);

    const handleChange = (value: boolean) => {
        onSelect?.(id);
        toggleIsSelected(value);
    };

    const title = (
        <FilterTreeTitle isSelected={isSelected} depth={depth} marginSize="small" color="subtle">
            {`${label} (${count})`}
        </FilterTreeTitle>
    );

    return (
        <StyledFormControlLabel
            control={
                <Checkbox
                    checked={isSelected}
                    onChange={(_event, value) => handleChange(value)}
                    name="checkedA"
                    color="primary"
                    disabled={loading}
                />
            }
            label={title}
            depth={depth}
        />
    );
};

const StyledFormControlLabel = styled(FormControlLabel, {
    shouldForwardProp: (prop) => prop !== "depth",
})<{ depth: number }>(({ theme, depth }) => ({
    alignItems: "flex-start",
    marginBlockStart: 1,
    marginBlockEnd: 1,
    marginInlineStart: Math.max(12, Math.max(12 * (depth - 2), 0)),
    wordBreak: "break-word",
    "& span": {
        padding: 0,
        marginInlineStart: theme.spacing(0.5),
    },
    '& input[type="checkbox"]': {
        padding: 0,
    },
}));
