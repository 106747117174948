// eslint-disable-next-line no-restricted-imports
import type { SvgIconComponent } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BlockIcon from "@mui/icons-material/Block";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { styled } from "@mui/material/styles";
import ApiErrorAlert from "components/ApiErrorAlert";
import { ButtonPrimary } from "components/Button";
import Copy from "components/Copy";
import { ProductImage } from "components/Image";
import PriceTypeLabel from "components/PriceTypeLabel";
import Text, { TextBody1, TextCaption } from "components/Text";
import { useNextTranslation } from "hooks/useTranslation";
import { useViewerIsHolibobAdmin } from "hooks/useViewer";
import React from "react";
import { productDurationToFormattedText } from "utils/time";

import { Flex, FlexProps } from "@holibob-packages/ui-core/components";
import { Spacer } from "@holibob-packages/ui-core/components";
import { FavouriteButton, FavouriteButtonProps } from "@holibob-packages/ui-core/components";
import { useLanguage } from "@holibob-packages/ui-core/hooks";
import { useProductPromotionConfig } from "@holibob-packages/ui-core/hooks";
import { Link, LinkProps } from "@holibob-packages/ui-core/navigation";
import { ProductReviewsInfo } from "@holibob-packages/ui-core/product";
import { makeStyles } from "@holibob-packages/ui-core/style";
import { ProductItem } from "@holibob-packages/ui-core/types";

import { useFavouriteButton } from "../hooks/useFavouriteButton";

export const LearnMoreButton = styled(ButtonPrimary)({ flex: 1, minWidth: 100, minHeight: 40 });
export const ProductCardTitleText = styled(Text)({
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
});

const useRibbonStyles = makeStyles()((theme) => ({
    ribbon: {
        margin: 0,
        background: "var(--ribbon-color)",
        color: "white",
        position: "absolute",
        top: 0,
        right: 0,
        transform: `translateX(30%) translateY(0%) rotate(${theme.direction === "ltr" ? 45 : -45}deg)`,
        transformOrigin: "top left",

        "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            margin: "0 -1px",
            width: "100%",
            height: "100%",
            background: "var(--ribbon-color)",
            left: "100%",
        },

        "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            margin: "0 -1px",
            width: "100%",
            height: "100%",
            background: "var(--ribbon-color)",
            right: "100%",
        },
    },
}));

const useStyles = makeStyles()((theme) => ({
    ribbon: {
        position: "absolute",
        top: 0,
        right: 0,
        width: 100,
        height: 100,
    },
    productImageContainer: {
        width: "100%",
        height: "100%",
        opacity: 1,
        transition: theme.transitions.create(["transform", "opacity"], {
            duration: theme.transitions.duration.short,
        }),
    },
    cardMedia: {
        height: "100%",
        overflow: "hidden",
        position: "relative",
    },
    desktopCard: {
        height: 230,
    },
    mobileCard: {
        position: "relative",
        "&:hover": {
            boxShadow: theme.boxShadows[2],
        },
    },
    mobileContent: {
        height: 210,
    },
    card: {
        cursor: "pointer",
    },
    truncated4: {
        display: "-webkit-box",
        WebkitLineClamp: 4,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        wordBreak: "break-word",
    },
    statText: {
        fontSize: 16,
    },
    favouriteButton: {
        position: "absolute",
        top: "10px",
        left: "10px",
    },
}));

type ProductCardImageRibbonProps = {
    product: $TSFixMe;
};

const ProductCardImageRibbon = ({ product }: ProductCardImageRibbonProps) => {
    const { promotionType, isFeatured, banner } = product;

    const config = useProductPromotionConfig({ banner, isFeatured, promotionType });
    const { classes: ribbonClasses } = useRibbonStyles();

    if (!config) return null;

    const style = { "--ribbon-color": config.color } as React.CSSProperties;

    return (
        <div style={style} className={ribbonClasses.ribbon}>
            <Text>{config.label}</Text>
        </div>
    );
};

interface ProductCardImageProps {
    width?: number;
    height?: number;
    product: $TSFixMe;
    sizes?: string;
    favouriteButtonSize?: FavouriteButtonProps["size"];
    onFavouriteChange?: (isFavourite: boolean) => void;
}

export const ProductCardImage = ({
    product,
    width,
    height,
    sizes,
    favouriteButtonSize = "small",
    onFavouriteChange,
}: ProductCardImageProps) => {
    const { previewImage } = product;
    const { classes, cx } = useStyles();
    const { id: productImageId } = previewImage || {};
    const {
        setIsFavourite,
        isFavourite,
        isAvailable: showFavouriteButton,
    } = useFavouriteButton(product.id, product.isFavourite);
    const handleFavouriteChange = async (val: boolean) => {
        await setIsFavourite(val);
        onFavouriteChange?.(val);
    };

    return (
        <Box position="relative">
            {showFavouriteButton && (
                <FavouriteButtonStyled
                    size={favouriteButtonSize}
                    isFavourite={isFavourite}
                    onChange={handleFavouriteChange}
                    color="light"
                />
            )}
            <CardMedia data-testid="productCardImage" title="Product Image" className={classes.cardMedia}>
                <div
                    style={{
                        width: width ?? "100%",
                        height: height ?? "100%",
                    }}
                >
                    <ProductImage
                        className={cx(classes.productImageContainer, "product-card-image")}
                        productImageId={productImageId}
                        alt="Product Image"
                        sizes={sizes}
                    />
                </div>
                <ProductCardImageRibbon product={product} />
            </CardMedia>
        </Box>
    );
};

const FavouriteButtonStyled = styled(FavouriteButton)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
    zIndex: 10,
}));

type ProductCardPricingProps = {
    product: $TSFixMe;
} & FlexProps;

export const ProductCardPricing = ({ product, ...props }: ProductCardPricingProps) => {
    const [t] = useNextTranslation("product");
    const perGroupLabel = t("label.perGroup");
    const fromLabel = t("label.from");
    const { guidePriceType, holibobGuidePrice } = product;

    if (!holibobGuidePrice?.gross) return null;

    let priceType = null;
    if (guidePriceType === "PER_GROUP") {
        priceType = <TextCaption>{perGroupLabel}</TextCaption>;
    }
    return (
        <Flex column middle bottom {...props} style={{ marginLeft: 20 }}>
            <Text data-testid="productCardPrice" variant="caption" component="p">
                {fromLabel} {holibobGuidePrice.currency}
            </Text>
            <PriceTypeLabel variant="h5" price={holibobGuidePrice} field="gross" />
            {priceType}
        </Flex>
    );
};

export const ProductCardButton = () => {
    const [t] = useNextTranslation("product");
    const learnMoreLabel = t("label.learnMore");

    const dataTestId = "learn-more-button";

    return (
        <LearnMoreButton data-testid={dataTestId}>
            <TextBody1 noWrap>{learnMoreLabel}</TextBody1>
        </LearnMoreButton>
    );
};

type ProductCardTitleProps = {
    id: string;
    name: string;
};

export const ProductCardTitle = (props: ProductCardTitleProps) => {
    const { name, id } = props;

    const isHolibobAdmin = useViewerIsHolibobAdmin();

    return (
        <Flex middle spaceBetween>
            <ProductCardTitleText data-testid="productCardTitle" gutterBottom variant={"h4"} color="primary">
                {name}
            </ProductCardTitleText>
            {isHolibobAdmin && <Copy value={id} label="Product Id" />}
        </Flex>
    );
};

type ProductCardDescriptionProps = {
    product: $TSFixMe;
};
const ProductCardDescription = ({ product }: ProductCardDescriptionProps) => {
    const { classes } = useStyles();
    const { description } = product;

    return (
        <Text data-testid="productCardDescription" variant={"body2"} className={classes.truncated4}>
            {description}
        </Text>
    );
};

type ProductCardCardProps = {
    href: string;
    newTab?: boolean;
    className?: string;
    children: React.ReactNode;
} & Pick<LinkProps, "onClick">;
const ProductCardCard = ({ href, children, className, newTab }: ProductCardCardProps) => {
    const { classes, cx } = useStyles();

    return (
        <Link data-testid="productCard" href={href} newTab={newTab}>
            <Card className={cx(classes.card, className)}>{children}</Card>
        </Link>
    );
};

type ProductCardErrorMessageProps = {
    product: $TSFixMe;
};
const ProductCardErrorMessage = ({ product }: ProductCardErrorMessageProps) => {
    const { error, id } = product;
    if (!error) return null;

    const message = `ProductId: ${id} \n ${error.message}`;
    return <ApiErrorAlert error={{ message }} />;
};

type ProductCardStatProps = {
    Icon: SvgIconComponent;
    label: string;
};
const ProductCardStat = ({ Icon, label }: ProductCardStatProps) => {
    const { classes } = useStyles();

    return (
        <Flex middle gap="5px">
            <Icon color="primary" />
            <Text className={classes.statText}>{label}</Text>
        </Flex>
    );
};

interface MetaItem {
    value: string;
    type: string;
}

type ProductCardDurationProps = {
    product: $TSFixMe;
};
const ProductCardDuration = ({ product }: ProductCardDurationProps) => {
    const { metaList } = product;
    const language = useLanguage();

    if (!metaList) return null;

    const minDuration = metaList.nodes.find(({ type }: MetaItem) => type === "MIN_DURATION")?.value;
    const maxDuration = metaList.nodes.find(({ type }: MetaItem) => type === "MAX_DURATION")?.value;
    const formattedText = productDurationToFormattedText(minDuration, maxDuration, language.codeShort);

    if (!formattedText) return null;

    return <ProductCardStat label={`${formattedText}`} Icon={AccessTimeIcon} />;
};

type ProductCardFreeCancellationProps = {
    product: $TSFixMe;
};
const ProductCardFreeCancellation = ({ product }: ProductCardFreeCancellationProps) => {
    const { cancellationPolicy } = product;
    const [t] = useNextTranslation("product");

    if (!cancellationPolicy) return null;

    const { hasFreeCancellation } = cancellationPolicy;

    if (!hasFreeCancellation) return null;

    return <ProductCardStat label={t("label.freeCancellation")} Icon={BlockIcon} />;
};

const ProductCardInstantConfirmation = () => {
    const [t] = useNextTranslation("product");
    return <ProductCardStat label={t("label.instantConfirmation")} Icon={FlashOnIcon} />;
};

type ProductCardStatsProps = {
    product: $TSFixMe;
};
export const ProductCardStats = ({ product }: ProductCardStatsProps) => {
    const { reviewRating, reviewCount } = product;

    return (
        <Flex column gap="5px">
            {reviewRating && reviewCount && <ProductReviewsInfo rating={reviewRating} reviewsNumber={reviewCount} />}
            <ProductCardDuration product={product} />
            <ProductCardInstantConfirmation />
            <ProductCardFreeCancellation product={product} />
        </Flex>
    );
};

type ProductCardMobileProps = {
    href: string;
    product: $TSFixMe;
    newTab?: boolean;
    sizes?: string;
    height?: number;
    onFavouriteChange: (isFavourite: boolean) => void;
    onProductClick?: (productItem: ProductItem) => void;
};
export const ProductCardMobile = ({
    href,
    product,
    sizes,
    height = 200,
    newTab,
    onFavouriteChange,
    onProductClick,
}: ProductCardMobileProps) => {
    const { classes, cx } = useStyles();

    const { error } = product;

    if (error) return <ProductCardErrorMessage product={product} />;

    const { id, name } = product;

    return (
        <ProductCardCard
            className={cx(classes.mobileCard)}
            href={href}
            newTab={newTab}
            onClick={() => {
                onProductClick?.({ id, name });
            }}
        >
            <ProductCardImage
                product={product}
                height={height}
                sizes={sizes}
                favouriteButtonSize="medium"
                onFavouriteChange={onFavouriteChange}
            />
            <CardContent className={classes.mobileContent}>
                <Flex column spaceBetween height="100%">
                    <ProductCardTitle id={id} name={name} />
                    <ProductCardStats product={product} />
                    <Flex spaceBetween bottom>
                        <ProductCardButton />
                        <ProductCardPricing product={product} />
                    </Flex>
                </Flex>
            </CardContent>
        </ProductCardCard>
    );
};

type ProductCardDesktopProps = {
    href: string;
    product: $TSFixMe;
    sizes: string;
    newTab?: boolean;
    onFavouriteChange?: (isFavourite: boolean) => void;
    onProductClick?: (productItem: ProductItem) => void;
};
export const ProductCardDesktop = ({
    href,
    product,
    sizes,
    newTab,
    onFavouriteChange,
    onProductClick,
}: ProductCardDesktopProps) => {
    const { classes, cx } = useStyles();

    const { error } = product;
    if (error) return <ProductCardErrorMessage product={product} />;

    const { id, name } = product;

    return (
        <ProductCardCard
            href={href}
            className={cx(classes.desktopCard)}
            newTab={newTab}
            onClick={() => {
                onProductClick?.({ id, name });
            }}
        >
            <Flex height="100%">
                <ProductCardImage product={product} width={300} sizes={sizes} onFavouriteChange={onFavouriteChange} />
                <CardContent>
                    <Flex spaceBetween>
                        <div>
                            <ProductCardTitle id={id} name={name} />
                            <Spacer sm />
                            <ProductCardDescription product={product} />
                        </div>
                        <Spacer sm horizontal />
                        <div>
                            <ProductCardPricing product={product} bottom />
                            <Spacer sm />
                            <ProductCardButton />
                        </div>
                    </Flex>
                </CardContent>
            </Flex>
        </ProductCardCard>
    );
};

export default ProductCardMobile;
