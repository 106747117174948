import { FetchPolicy } from "@apollo/client";
import useProductFilteredList, { useProductListTrees } from "hooks/api/useProductFilteredList";
import useListSearch from "hooks/useListSearch";
import useLoading from "hooks/useLoading";
import { useNextTranslation } from "hooks/useTranslation";
import { useSearchParams } from "next/navigation";
import { createContext, useCallback, useContext, useMemo } from "react";
import { objectToQueryParams } from "utils/objectToQueryParams";

import { useRouter } from "@holibob-packages/ui-core/navigation";
import { UrlQueryParams } from "@holibob-packages/url";

import { useProductFilteredPriceSlider } from "./ProductFilteredPriceSlider";

export const ProductFilteredListStateContext = createContext<
    Record<string, never> | ProductFilteredListStateContextValue
>({});

export type ProductFilteredListStateContextValue = UseProductFilteredListCreateContextParams & {
    total?: number;
    interval?: string;
    curationIdsPath?: string;
    treesResponse?: $TSFixMe;
    productsResponse?: $TSFixMe;
    loading?: boolean;
    header?: JSX.Element | null;
    sort?: Record<string, string>;
    error?: { message: string };
    onProductClick?: (id: string) => void;
    onChange?: (action?: $TSFixMe) => void;
    hide?: boolean;
};

export type UseProductFilteredListCreateContextParams = {
    showFiltersTree?: boolean;
    showFilters?: boolean;
    showSearch?: boolean;
    showTitle?: boolean;
    title?: JSX.Element;
    filter: {
        curationSlugId?: string;
        categoryIds?: string[];
        productIds?: string[];
        placeId?: string;
        search?: string;
        providerIds?: string[];
        priceStart?: string;
        priceEnd?: string;
    };
    searchTerm: string;
    hideUnlessProducts?: boolean;
    fetchPolicy?: FetchPolicy;
    treeIds?: ("CATEGORY" | "ATTRIBUTE" | "CITY" | "PROVIDER" | "PRICE")[];
};

export type BaseTree = {
    id: string;
    type: string;
    label: string;
    fieldName: string;
    canReset: boolean;
    key?: string;
    kind?: "TREE" | "PRICE";
};

export type Tree = BaseTree & {
    filter?: string[];
    branches?: Tree[] | { nodes: Tree[] };
    isSelected?: boolean;
    selectedCount?: number;
    onSelect?: (id: string) => void;
    onClear?: () => void;
};

export function useProductFilteredListCreateContext(
    props: UseProductFilteredListCreateContextParams
): ProductFilteredListStateContextValue {
    const { filter: filterProp = {}, treeIds = [], hideUnlessProducts = false, showFiltersTree, fetchPolicy } = props;
    const router = useRouter();
    const [t] = useNextTranslation("product");
    const searchParams = useSearchParams();
    const curationPathParam = searchParams.get(UrlQueryParams.curationPath);

    const [state, onChange] = useListSearch({
        initialParams: { filter: filterProp },
        queryParamsFactory: (searchParams) =>
            objectToQueryParams({ [UrlQueryParams.curationPath]: curationPathParam, ...searchParams }),
    });

    const onProductClick = useCallback(
        (id: string) => {
            router.push(`/product/${id}`);
        },
        [router]
    );

    const { filter = {}, sort, page, pageSize } = state;

    const productsResponse = useProductFilteredList({
        variables: { filter, sort, page: page + 1, pageSize },
        options: { ...(fetchPolicy && { fetchPolicy }) },
    });

    const treesResponse = useProductListTrees({
        variables: { filter, sort, page: page + 1, pageSize },
        options: { skip: !showFiltersTree },
        treeIds,
    });

    const loading = productsResponse.loading;
    const data = productsResponse?.data ?? productsResponse?.previousData;

    const products = data?.productList?.products;
    const total = productsResponse?.data?.productList?.total ?? 0;

    const { nextPage, prevPage } = productsResponse;

    const startCursor = page * pageSize + 1;
    const endCursor = Math.min(page * pageSize + pageSize, total);
    const interval = t("message.interval", {
        start: startCursor,
        end: endCursor,
        ...(!nextPage &&
            !prevPage && {
                context: "all",
            }),
    });

    const hide = !loading && hideUnlessProducts && !products?.length;

    useLoading(loading);

    return useMemo(() => {
        return {
            productsResponse,
            treesResponse,
            ...props,
            ...state,
            onChange,
            onProductClick,
            treeIds,
            loading,
            hide,
            total,
            interval,
        };
    }, [
        productsResponse,
        treesResponse,
        props,
        state,
        onChange,
        onProductClick,
        treeIds,
        loading,
        hide,
        total,
        interval,
    ]);
}

export function useProductFilteredListContext() {
    return useContext(ProductFilteredListStateContext);
}

export function useProductFilteredListProducts() {
    const { productsResponse } = useProductFilteredListContext();
    return productsResponse.products;
}

export function useProductFilteredListLoading() {
    const { loading } = useProductFilteredListContext();
    return loading;
}

export function useProductFilteredListSearchTerm() {
    const { searchTerm } = useProductFilteredListContext();
    return searchTerm;
}

export function useProductFilteredListHeader() {
    const { header } = useProductFilteredListContext();
    return header;
}

export function useProductFilteredListTitle() {
    const { searchTerm, title, total, interval } = useProductFilteredListContext();

    const [t] = useNextTranslation("product");

    const showResultsMessage = t("message.showResults", { count: total, interval });

    if (title) return title;

    if (searchTerm) return `${showResultsMessage} '${searchTerm}'`;

    return null;
}

export function useProductFilteredCurationIdsPath() {
    return useProductFilteredListContext().curationIdsPath;
}

export function useProductFilteredListFilter() {
    const { filter } = useProductFilteredListContext();
    return filter;
}

export function useProductFilteredListSort() {
    const { sort } = useProductFilteredListContext();
    return sort;
}

export function useProductFilteredListError() {
    const { error } = useProductFilteredListContext();
    return error;
}

export function useProductFilteredListOnProductClick() {
    const { onProductClick } = useProductFilteredListContext();
    return onProductClick;
}

export function useProductFilteredListOnChange() {
    const { onChange } = useProductFilteredListContext();
    return onChange;
}

export function useProductFilteredListTreeOnChangeSearch() {
    const onChange = useProductFilteredListOnChange();
    const type = "FILTER_SET_KEY";
    const key = "search";

    return useCallback(
        (search: string) => {
            const action = { type, payload: { key, value: search } };
            onChange?.(action);
        },
        [onChange]
    );
}

export function useProductFilteredListTreeOnClearSearch() {
    const onChange = useProductFilteredListOnChange();
    const type = "FILTER_CLEAR_KEY";
    const key = "search";

    return useCallback(() => {
        const action = { type, payload: { key } };
        onChange?.(action);
    }, [onChange]);
}

const buildOnSelectPerTree = (tree: Tree, onChange?: ProductFilteredListStateContextValue["onChange"]) => {
    const { key } = tree;

    return (id: string) => {
        let type = "FILTER_SET_KEY";

        if (tree.filter?.includes(id)) tree.filter = tree.filter.filter((i) => i !== id);
        else {
            tree.filter = [...(tree.filter ?? []), id];
        }

        const value = tree.filter;

        if (value.length === 0) type = "FILTER_CLEAR_KEY";
        const action = { type, payload: { key, value } };

        onChange?.(action);
    };
};

const buildOnClearPerTree = (tree: Tree, onChange?: ProductFilteredListStateContextValue["onChange"]) => {
    const { key } = tree;
    const type = "FILTER_CLEAR_KEY";
    const payload = { key };
    const action = { type, payload };

    return () => {
        onChange?.(action);
    };
};

const processTree = (tree: Tree) => {
    const { branches: _branches = [] } = tree;
    const branches = "nodes" in _branches ? _branches.nodes : _branches;

    tree.isSelected = branches.some(({ isSelected }) => isSelected);
    tree.selectedCount = branches.reduce<number>((acc, { selectedCount }) => acc + (selectedCount ?? 0), 0);
};

export function useProductFilteredListTrees() {
    const [t] = useNextTranslation("product");
    const categoryLabel = t("label.category");
    const attributeLabel = t("label.attribute");
    const placesLabel = t("label.places");
    const providerLabel = t("label.provider");
    const priceTree = useProductFilteredPriceSlider();
    const { treeIds, treesResponse, filter = {} } = useProductFilteredListContext();
    const onChange = useProductFilteredListOnChange();

    const FILTER_TREES_BY_ID = useMemo<
        Record<NonNullable<UseProductFilteredListCreateContextParams["treeIds"]>[number], BaseTree>
    >(
        () =>
            ({
                CATEGORY: {
                    fieldName: "categoryTree",
                    id: "CATEGORY",
                    key: "categoryIds",
                    label: categoryLabel,
                    canReset: true,
                    type: "BRANCH",
                },
                ATTRIBUTE: {
                    fieldName: "attributeTree",
                    id: "ATTRIBUTE",
                    key: "attributeIds",
                    label: attributeLabel,
                    canReset: false,
                    type: "BRANCH",
                },
                CITY: {
                    fieldName: "cityTree",
                    id: "CITY",
                    key: "placeIds",
                    label: placesLabel,
                    canReset: true,
                    type: "BRANCH",
                },
                PROVIDER: {
                    fieldName: "providerTree",
                    id: "PROVIDER",
                    key: "providerIds",
                    label: providerLabel,
                    canReset: true,
                    type: "BRANCH",
                },
                PRICE: {
                    ...priceTree.PRICE,
                    kind: "PRICE",
                },
            }) as const,
        [attributeLabel, categoryLabel, placesLabel, providerLabel, priceTree.PRICE]
    );

    const trees = useMemo(() => {
        const trees = treeIds?.reduce<Record<(typeof treeIds)[number], Tree>>(
            (acc, id) => {
                const tree: Tree = FILTER_TREES_BY_ID[id];

                if (treesResponse && id !== "PRICE") {
                    const treeFilter = filter[tree.key as keyof typeof filter] ?? [];
                    tree.filter = Array.isArray(treeFilter) ? treeFilter : [treeFilter];
                    tree.onSelect = buildOnSelectPerTree(tree, onChange);
                    tree.onClear = buildOnClearPerTree(tree, onChange);
                    tree.branches = treesResponse[tree.fieldName];
                    processTree(tree);
                }
                return { ...acc, [id]: tree };
            },
            {} as Record<(typeof treeIds)[number], Tree>
        );

        return trees;
    }, [treeIds, FILTER_TREES_BY_ID, treesResponse, filter, onChange]);

    return trees;
}

export function useProductFilteredListOnChangeSelect() {
    const onChange = useProductFilteredListOnChange();

    return useCallback(
        (newValue: string | null, currentValue: string | null) => {
            const actions = [];
            if (newValue === currentValue) return;
            if (currentValue) {
                actions.push({
                    type: "FILTER_CLEAR_KEY",
                    payload: { key: currentValue },
                });
            }
            if (newValue) {
                actions.push({
                    type: "FILTER_SET_KEY",
                    payload: { key: newValue, value: true },
                });
            }
            if (actions.length) onChange?.(actions);
        },
        [onChange]
    );
}

export function useProductFilteredListResetFilters() {
    const onChange = useProductFilteredListOnChange();

    return useCallback(() => onChange?.({ type: "RESET" }), [onChange]);
}

export function useProductFilteredListOnChangeSort() {
    const onChange = useProductFilteredListOnChange();

    return useCallback(
        (value: string) => {
            const [key, dir] = value.split(":");
            const sort = { [key]: dir };
            const type = "SORT_CHANGE";
            const action = { type, payload: { sort } };
            onChange?.(action);
        },
        [onChange]
    );
}

export function useProductFilteredListPaginationProps() {
    const { productsResponse, onChange } = useProductFilteredListContext();
    const { nextPage, prevPage, pagination } = productsResponse;

    return {
        nextPage,
        prevPage,
        onChange,
        pagination,
    };
}

export function useAnalyticsEntityData() {
    const searchParams = useSearchParams();
    const curationId = searchParams.get("curationId");
    const placeId = searchParams.get("placeId");
    const categoryId = searchParams.get("categoryId");

    if (curationId) return { entityId: curationId, entityType: "Curation" };

    if (placeId) return { entityId: placeId, entityType: "Place" };

    if (categoryId) return { entityId: categoryId, entityType: "Category" };

    return { entityId: undefined, entityType: undefined };
}
