import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";

import { useProductFilteredListTrees } from "../hooks";
import { FilterTree } from "./ProductFilteredListFilterTree";

export const ProductFilteredListFilterTrees = () => {
    const trees = useProductFilteredListTrees();
    if (!trees) {
        return null;
    }
    const categoryTree = trees["CATEGORY"];
    const attributeTree = trees["ATTRIBUTE"];
    const providerTree = trees["PROVIDER"];
    const priceTree = trees["PRICE"];
    const combinedTree = [categoryTree, attributeTree].map((tree) => {
        if (tree.id !== "ATTRIBUTE") {
            return tree;
        }

        const rawBranches = tree.branches ?? [];
        const branches = "nodes" in rawBranches ? rawBranches.nodes : rawBranches;

        [priceTree, providerTree].filter(Boolean).forEach((branch) => {
            const exists = branches.some((existingBranch) => existingBranch.id === branch.id);
            if (!exists) {
                branches.unshift(branch);
            }
        });

        tree.branches = branches;
        return tree;
    });

    return (
        <FilterTreesWrapper>
            {combinedTree.map((tree, key) => (
                <FilterTreesCard key={key}>
                    <CardContent>
                        <FilterTree {...tree} />
                    </CardContent>
                </FilterTreesCard>
            ))}
        </FilterTreesWrapper>
    );
};

const FilterTreesWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.25),
}));

const FilterTreesCard = styled(Card)(({ theme }) => ({
    border: "none",
    backgroundColor: theme.palette.grey[100],
}));
